import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Burn-in test equipment`}</h4>
    <p>{`Burn-in tools heat devices to check if defects cause the devices to fail.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      